.CardPage{
    margin-top: 120px;
    margin-right: 70px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.HeroInfo{
    margin-top: 60px;
    width: 100%;
    height: 850px;
    display: flex;
    justify-content: space-evenly;
    
}


.AboutHero{
    margin-left: 40px;
    width: 60%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-content: baseline;
    flex-wrap: wrap;
    color: white;
}



.HeroSkilltxt{
    width: 100%;
    color : #b5227b;
    font-size: 25px;
    margin: 0px;
    padding: 0px;
}

.HeroName{
    width: 100%;
    font-size: 60px;
    margin : 0;
}

.HeroAbout{
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
    width: 65%;
    margin-right: 30%;
}

.Abilitiestxt{
    margin-top: 100px;
    margin-bottom: 0px;
    margin-right: 90px;
    font-size: 40px;
}

.abilities-background{
    margin-right: 20px;
    width: 80px;
    height: 80px;
}

.border{
    background: linear-gradient(to right, white, transparent);
    width : 100%;
    height: 1px;
    margin: 8px;
    margin-left: 0px;
}




.ulAbilites{
    list-style-type: none;
    padding: 10px;
    margin: 4px;
    padding-left: 0px;
    margin-left: 0px;
}

.ulAbilites > li{
    font-size: 40px;
    padding: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center; 
}

.ulAbilites > li > a {
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center; 
    gap: 8px;
}

a{
    font-size: 20px;
    text-decoration: none;
    color : white;
    padding: 8px;
    padding-left: 0px;
}



.animepic {
    margin-left: auto;
    height: auto;
    width: 40%;
    display: flex;
}

.wrapper-div {
    margin-left: 150px;
    position: relative;
}

#animepic {
    margin-left: auto;
    margin-right: 20px;
    width: 540px;
    height: 750px;
    z-index: 3;
    position: relative; 
    right: -100%; /* Start off-screen to the left */
    animation: swipe-in 1s forwards; /* 1s duration for swipe-in */
}

#background-animepic {
    padding: 50px;
    width: 400px;
    height: 400px;
    position: relative; 
    z-index: 2; 
    bottom: 470px;
    right: 60px;
    background: linear-gradient(to bottom, #b5227b, black);
    border-radius: 350px;
}

/* Keyframes for swipe-in effect */
@keyframes swipe-in {
    to {
        right: calc(114% - 100%); /* Adjust the final position based on your layout */
    }
}


.Gallery{
    color: white;
    width: 100%;
    height: auto;
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap ;
    margin: 25px;
    margin-top: 180px;
    margin-right: 70px;
    margin-left: 70px;
}

.border-gallery{
    background: linear-gradient(to right, white, transparent);
    width : 50%;
    height: 1px;
    margin: 8px;
    margin-left: 0px;
}

.Gallerytxt{
    font-size: 35px;
    margin-right: 4px;

}

.ImageCard{
    width: auto;
    height: auto;
    margin: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#ImageCard1,#ImageCard2 {
    opacity: 70%;
    height: 300px;
    width: 450px;
    border-radius: 8px;
    margin: 12px;
    border: 1.5px solid white;
}

.ImageCard-Wrapper{
    transition: transform 0.3s ease;
}
.ImageCard-Wrapper:hover{
    transform: scale(0.9);
    opacity: 50%;
}


.ImageCard1-span{
    position: relative;
    bottom: 120px;
    left : 20px;
    font-size: 20px;
    font-weight: 600;

}

.circle-div{
    position: relative;
    z-index : 10;
    bottom : 130px;
    left : 25px;
    width: 50px;
    height: 50px;
    border: 1px solid #b22179;
    border-radius: 50%;
    background-color: #511039;
    display: flex;
    justify-content: center;
    align-items: center;
}


.circle-div > img{
    position: absolute;
    margin-left: 5px;
    width: auto;
    height: auto;
}





/*For your average Phone*/
@media (max-width : 825px) {
    .CardPage{
        margin-top:200px;
    }
    .Abilitiestxt, .ulAbilites, .border{
        display: None;
    }

    .HeroInfo{
        flex-wrap: wrap;
        margin-right: auto;
    }

    .AboutHero,.HeroAbout, .animepic{
        width: 100%;
    }

    .HeroAbout{
        margin: 0px;
        margin-top: 15px;
    }


    .animepic{
        justify-content: center;
        margin-top: 15px;
    }

    #animepic{
        margin: 0px;
        
    }

    .AboutHero{
        margin: 0px;
        text-align: center;
        width : 100%;
        justify-content: center;
        align-items: center;
    }
    
    .Gallery{
        z-index : 9999;
        width: 100%;
        height: auto;
        margin-top: 300px;
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ImageCard{
        justify-content: center;
        align-items: center;
    }

    /* Keyframes for swipe-in effect */
    @keyframes swipe-in {
        to {
            right: calc(115% - 100%); /* Adjust the final position based on your layout */
        }
    }

    #ImageCard1{
        z-index: 9;
    }

   .ImageCard-Wrapper:hover{

        transform: scale(0.9);
        opacity: 50%;
    }


    #background-animepic{
        margin-right: 30px;
    }
}




/*For width greater than 825px and lower than 1000px*/
@media (max-width : 1200px) {
    .CardPage{
        margin-top:140px;
    }
    .Abilitiestxt, .ulAbilites, .border{
        display: None;
    }

    .HeroInfo{
        flex-wrap: wrap;
        margin-right: auto;
    }

    .AboutHero,.HeroAbout, .animepic{
        width: 100%;
    }

    .HeroAbout{
        margin: 0px;
        margin-top: 15px;
    }


    .animepic{
        justify-content: center;
        margin-top: 15px;
    }

    #animepic{
        margin: 0px;
        
    }

    .AboutHero{
        text-align: center;
        width : 100%;
        justify-content: center;
        align-items: center;
    }
    
    .Gallery{
        z-index : 9999;
        width: 100%;
        height: auto;
        margin-top: 300px;
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ImageCard{
        justify-content: center;
        align-items: center;    
    }

    /* Keyframes for swipe-in effect */
    @keyframes swipe-in {
        to {
            right: calc(115% - 100%); /* Adjust the final position based on your layout */
        }
    }

    #ImageCard1{
        z-index: 9;
    }

   .ImageCard-Wrapper:hover{

        transform: scale(0.9);
        opacity: 50%;
    }

    .animepic {
        margin-right: auto;
        height: auto;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}



/*width of less than 400px*/
@media (max-width : 450px){
    .CardPage{
        margin-top:150px;
    }

    .Abilitiestxt, .ulAbilites, .border{
        display: None;
    }

    .HeroInfo{
        flex-wrap: wrap;
        margin-right: auto;
    }

    .AboutHero{
        margin: 0px;
    }
    
    .AboutHero,.HeroAbout, .animepic{
        width: 100%;
    }

    .HeroAbout{
        margin: 0px;
        margin-top: 15px;
    }


    .animepic{
        justify-content: center;
        margin-top: 15px;
    }

    #animepic{
        margin: 0px;
        
    }

    .AboutHero{
        text-align: center;
        width : 100%;
        justify-content: center;
        align-items: center;
    }
    
    .Gallery{
        z-index : 9999;
        width: 100%;
        height: auto;
        margin-top: 350px;
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ImageCard{
        justify-content: center;
        align-items: center;
        
    }

    /* Keyframes for swipe-in effect */
    @keyframes swipe-in {
        to {
            right: calc(112% - 100%); /* Adjust the final position based on your layout */
        }
    }

    #ImageCard1{
        z-index: 9;
    }

    #ImageCard1,#ImageCard2{
        width : 300px;
        height: 211px;
    }

   .ImageCard-Wrapper:hover{

        transform: scale(0.9);
        opacity: 50%;
    }

    .animepic {
        height: auto;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #background-animepic {
        padding: 50px;
        width: 300px;
        height: 300px;
        position: relative;
        z-index: 2;
        bottom: 470px;
        left: 4px;
        background: linear-gradient(to bottom, #b5227b, black);
        border-radius: 350px;
        margin-right: 10px;
    }

}
