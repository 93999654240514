.events {
  background: transparent; /* Make background transparent */
  text-align: center;
  margin-bottom: 50px;
}

.events h1 {
  font-size: 2.5rem;
  margin-top: 22vh;
  margin-bottom: 2rem;
  color: #ee4e1c; /* Change text color to white */
}

.events-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.event-card {
  width: 25%;
  margin: 1.5rem;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.event-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ff5733;
}

.event-card p {
  font-size: 1rem;
  color: #fff; /* Change text color to white */
}

@media (max-width: 768px) {
  .events {
    padding: 3rem 1rem;
  }

  .event-card {
    width: 100%;
    margin: 1rem 0;
  }
}
