body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(
    to bottom,
    #ff7e5f,
    #feb47b
  ); /* Example gradient */
  color: #0e0101;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}
