.App {
  font-family: "Arial", sans-serif;
  overflow-x: hidden;
  background-color: black;
  
}

.section {
  padding: 5rem 2rem;
  text-align: center;
  background-color: transparent; /* Ensure sections have transparent background */
}

@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}
body{
  background-color: black;
}