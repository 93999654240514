/* Base styles */
:root {
  --primary-color: #ff5733;
  --primary-hover: #e04e2a;
  --text-color: #ffffff;
  --dark-bg: #010000;
  --gradient-overlay: linear-gradient(
    180deg,
    transparent,
    rgba(1, 0, 0, 0.8),
    var(--dark-bg)
  );
}

/* Hero Section */
.hero {
  margin: 0 !important;
  padding: 50px;
  background: url("../../public/background.jpg") center/cover;
  color: var(--text-color);
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  min-height: 100vh;
}

/* Modified main overlay with reduced opacity at top */
.hero::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,      /* Completely transparent */
    rgba(0, 0, 0, 0) 20%,     /* Still completely transparent */
    rgba(0, 0, 0, 0) 40%,     /* Still completely transparent */
    rgba(0, 0, 0, 0.3) 60%,   /* Start darkening here */
    rgba(0, 0, 0, 0.6) 75%,   /* Darker */
    rgba(0, 0, 0, 0.8) 85%,   /* Very dark */
    rgb(0, 0, 0) 100%         /* Complete black */
  );
  z-index: 0;
}

/* Adjusted bottom blend */
.hero::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;  /* Increased height for smoother transition */
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 40%,   /* Reduced middle opacity */
    rgba(0, 0, 0, 0.8) 70%,   /* Added intermediate step */
    rgb(0, 0, 0) 100%
  );
  z-index: 1;
}


/* Rest of the styles remain unchanged */
.hero-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  text-align: left;
  margin: 0 5vw;
}

.hero-title,
.hero-subtitle {
  position: relative;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

.hero-title::before,
.hero-subtitle::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  z-index: -1;
}

.hero-title {
  margin: 5vh 0 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.hero-subtitle {
  margin: 3vh 0 20px;
  font-size: 24px;
  color: rgb(204, 204, 204);
}

.hero h1 {
  font-size: 4rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
  margin: 1rem 0;
  font-size: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-buttons {
  margin-top: 50vh;
  display: flex;
  gap: 20px;
  align-items: center;
}

.cta-button {
  margin-top: 20px;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-hover);
}

/* Play Button */
.lightbox-trailer {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.wrap-play-button {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.play-button {
  transform: scale(0.3);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.play-text {
  font-weight: 900;
  position: absolute;
  left: 50%;
  margin-left: 9rem;
  transform: translateX(-50%);
  color: var(--text-color);
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
  pointer-events: none;
  font-family: "Courier New", Courier, monospace;
}

.wrap-play-button:hover {
  .play-text {
    opacity: 1;
  }
  .play-button {
    transform: scale(0.9);
  }
}

/* Modal */
.modal {
  position: relative;
  background: var(--text-color);
  padding: 20px 20px 50px;
  width: 960px;
  height: 528px;
  max-width: 90%;
  margin: 50px auto;
  z-index: 101;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.video-iframe {
  width: 100%;
  height: 100%;
}

.close-button {
  margin-top: 10px;
  background: red;
  color: var(--text-color);
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Scrolling Text */
.scrolling-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: 
    /* Top dark gradient */
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.7) 15%,
      transparent 30%
    ),
    /* Left C-shaped curve */
    radial-gradient(
      circle at -20% 50%,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.8) 30%,
      rgba(0, 0, 0, 0.4) 60%,
      transparent 80%
    ),
    /* Bottom dark gradient */
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.7) 15%,
      transparent 30%
    ),
    /* Overall maroon tint */
    radial-gradient(
      circle at 75% 50%,
      rgba(80, 0, 0, 0.3) 0%,
      rgba(40, 0, 0, 0.4) 50%,
      rgba(20, 0, 0, 0.5) 100%
    );
  color: var(--text-color);
  margin-top: -100px;
  padding-top: 100px;
  z-index: 2;
  min-height: 100vh;
  transition: background 0.5s ease;
}

.scrolling-text-container::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  background: rgba(20, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  transition: backdrop-filter 0.5s ease;
}
.scrolling-text-container h1 {
  z-index: 3;
  font-size: 8vw;
  color: var(--text-color);
}

.horizontal-line,
.icon-show,
.scrolling-text {
  position: relative;
  z-index: 3;
}

.horizontal-line {
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.3)
  );
}

/* Icon Styles */
.icon-show {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 100px;
}

.icon-center {
  position: absolute;
  top: -52.5px;
  left: 50%;
  transform: translateX(-50%) rotate(var(--rotation-angle));
  padding: 20px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2b2b2b;
  z-index: 1;
}

/* Scrolling Text Animation */
.scrolling-text {
  display: flex;
  animation: scroll-text 35s linear infinite;
  white-space: nowrap;
  font: 900 288px "Futura font", sans-serif;
  text-transform: uppercase;
  line-height: 100%;
  padding: 100px 0;
  margin: 100px;
}

.scrolling-text-item {
  display: inline-block;
  padding: 0 50px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ffffff, #c2c2c2, #414040, #2b2b2b);
}

.scrolling-text-item:nth-child(2n) {
  background-image: linear-gradient(90deg, #ffffff, #2b2b2b);
}

/* Particles */
.particle-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.particle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  transition: transform 0.3s ease-out;
  z-index: 1;
  pointer-events: none;
  filter: blur(var(--blur-amount));
}

.particle1 {
  --blur-amount: 10px;
  background-color: #fc491c;
  width: 50px;
  height: 50px;
  animation: floatParticle1 12s infinite;
}
.particle2 {
  --blur-amount: 6px;
  background-color: #fa5e1e;
  width: 35px;
  height: 35px;
  animation: floatParticle2 10s infinite;
}
.particle3 {
  --blur-amount: 8px;
  background-color: #ff5733;
  width: 40px;
  height: 40px;
  animation: floatParticle3 14s infinite;
}
.particle4 {
  --blur-amount: 5px;
  background-color: #ffa600;
  width: 20px;
  height: 20px;
  animation: floatParticle4 11s infinite;
}
.particle5 {
  --blur-amount: 8px;
  background-color: #ff5733;
  width: 45px;
  height: 45px;
  animation: floatParticle5 13s infinite;
}
.particle6 {
  --blur-amount: 5px;
  background-color: #fa5e1e;
  width: 25px;
  height: 25px;
  animation: floatParticle6 9s infinite;
}

/* Animations */
@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes floatParticle1 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.7;
  }
  50% {
    transform: translate(-50%, -150%) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: translate(100%, 100%) rotate(360deg);
    opacity: 0.3;
  }
}

@keyframes floatParticle2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: translate(50%, -100%) rotate(-180deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(-100%, 150%) rotate(-360deg);
    opacity: 0.2;
  }
}

@keyframes floatParticle3 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.5;
  }
  50% {
    transform: translate(-30%, -200%) rotate(270deg);
    opacity: 0.3;
  }
  100% {
    transform: translate(80%, 120%) rotate(-270deg);
    opacity: 0.1;
  }
}

@keyframes floatParticle4 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.7;
  }
  50% {
    transform: translate(40%, -130%) rotate(-90deg);
    opacity: 0.5;
  }
  100% {
    transform: translate(-70%, 180%) rotate(90deg);
    opacity: 0.2;
  }
}

@keyframes floatParticle5 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: translate(-20%, -180%) rotate(45deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(60%, 100%) rotate(-45deg);
    opacity: 0.1;
  }
}

@keyframes floatParticle6 {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.5;
  }
  50% {
    transform: translate(30%, -100%) rotate(135deg);
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, 150%) rotate(-135deg);
    opacity: 0.2;
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .hero {
    background-position: 80% center;
    padding-bottom: 50px;
  }
  .hero h1 {
    font-size: 50px;
  }
  .hero p {
    width: 90%;
    font-size: 18px;
    line-height: 1.4;
  }
  .horizontal-line {
    width: 75vw;
    height: 1px;
  }
}

@media (max-width: 768px) {
  .hero {
    background-position: 90% center;
    padding: 50px;
  }
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1.1rem;
    padding: 0 10px;
  }
  .play-button {
    width: 50px;
    height: 50px;
  }
  .wrap-play-button {
    flex-direction: column;
  }
  .cta-button {
    padding: 0.75rem 1.5rem;
  }
  .scrolling-text,
  .scrolling-text-item {
    font-size: 72px;
  }
  .scrolling-text-item {
    padding: 0 25px;
  }
  .play-text {
    margin-left: 0;
    font-size: 0.9rem;
  }
  .modal {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .hero {
    background-position: 90% center;
    padding: 15px;
  }
  .hero h1 {
    font-size: 60px;
  }
  .hero p {
    font-size: 1.5rem;
  }
  .play-button {
    width: 45px;
    height: 45px;
  }
  .play-text {
    font-size: 0.8rem;
  }
  .modal {
    width: 100%;
    height: 30%;
  }
  .video-iframe {
    height: 95%;
  }
}

@media (max-width: 380px) {
  .hero h1 {
    font-size: 50px;
  }
  .hero p {
    width: 90%;
    font-size: 18px;
    line-height: 1.4;
  }
  .horizontal-line {
    width: 90%;
    height: 1px;
  }
}
