.footer_section {
  background-color: black;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  color: white;
}

/* Red line at the top */
.red-line {
  width: 90%;
  height: 12px;
  margin-bottom: 140px;
  border: 9px dashed #000;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(1, 0, 0, 0)),
    color-stop(53%, #ee1c25),
    to(rgba(1, 0, 0, 0))
  );
  background-image: linear-gradient(
    90deg,
    rgba(1, 0, 0, 0),
    #ee1c25 53%,
    rgba(1, 0, 0, 0)
  );
  position: absolute;
  top: 0;
}

/* Sparkle animation */
.sparkle {
  position: absolute;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  animation: sparkleAnimation 1.5s ease-in-out forwards;
}

@keyframes sparkleAnimation {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

.container {
  margin-top: 100px !important;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.wrap_footer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.link-logo-footer {
  display: block;
  margin-bottom: 80px;
}

.logo_footer {
  height: 120px;
  width: auto;
  display: block;
}

/* Paragraphs */
.paragraph_1 {
  color: #808080;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  text-transform: uppercase;
}

.paragraph_1_align_center {
  text-align: center;
}

.paragraph_1_margin_bottom_80px {
  margin-bottom: 80px;
}

.link_paragraph_1 {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.link_paragraph_1:hover {
  color: #cccccc;
}

/* Base spark styles */
.spark,
.spark-1,
.spark-2,
.spark-4 {
  position: absolute;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease;
}

.spark {
  top: 15%;
  right: 20%;
  animation: sparkFloat 4s ease-in-out infinite;
}

.spark-1 {
  right: 35%;
  bottom: 25%;
  animation: sparkFloat 4s ease-in-out infinite 1s;
}

.spark-2 {
  left: 35%;
  top: 20%;
  animation: sparkFloat 4s ease-in-out infinite 2s;
}

.spark-4 {
  left: 20%;
  bottom: 30%;
  animation: sparkFloat 4s ease-in-out infinite 3s;
}

@keyframes sparkFloat {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, -10px);
  }
}

/* Responsive Breakpoints */
@media screen and (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .paragraph_1 {
    font-size: 16px;
  }

  .spark,
  .spark-1,
  .spark-2,
  .spark-4 {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .footer_section{
    margin-top: 50px;
  }
  .container{
    margin-top: 100px !important;
  }

  .footer_section {
    padding: 30px 15px;
  }

  .link-logo-footer {
    margin-bottom: 60px;
  }

  .logo_footer {
    height: 100px;
  }

  .paragraph_1_margin_bottom_80px {
    margin-bottom: 60px;
  }

  .spark,
  .spark-1,
  .spark-2,
  .spark-4 {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 479px) {
  .footer_section{
    margin-top: 50px;
  }
  .container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px !important;
  }

  .footer_section {
    padding: 20px 10px;
  }

  .link-logo-footer {
    margin-bottom: 40px;
  }

  .logo_footer {
    height: 80px;
  }

  .paragraph_1 {
    font-size: 14px;
  }

  .paragraph_1_margin_bottom_80px {
    margin-bottom: 40px;
  }

  .spark,
  .spark-1,
  .spark-2,
  .spark-4 {
    width: 12px;
    height: 12px;
  }
}

/* Extra small devices */
@media screen and (max-width: 360px) {
  .footer_section{
    margin-top: 100px;
  }

  .container{
    margin-top: 50px !important;
  }

  .spark,
  .spark-1,
  .spark-2,
  .spark-4 {
    width: 8px;
    height: 8px;
  }

  .logo_footer {
    height: 70px;
  }

  .paragraph_1 {
    font-size: 12px;
  }
}