.header {
  position: absolute;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0);
  padding: 1rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 1000;
  box-sizing: border-box;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  /* backdrop-filter: blur(2px); */
}

/* Logo Styles */
.logo {
  text-decoration: none;
  color: white;
}

.logo img {
  width: 200px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.7);
  margin-left: 8vh;
}

/* Fire Animation */
@keyframes fireEffect {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9) rotate(10deg);
  }
  50% {
    transform: scale(0.7) rotate(-10deg);
  }
  75% {
    transform: scale(0.9) rotate(10deg);
  }
  100% {
    transform: scale(1);
  }
}

.fire-animation {
  animation: fireEffect 2.5s ease-in-out;
}

/* Desktop Navigation */
.slide-menu {
  cursor: pointer !important;
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  width: 300px;
  background-color: rgba(17, 17, 17, 0.2);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  padding-top: 80px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
}

.slide-menu.open {
  right: 0;
}

.slide-menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.slide-menu nav ul li {
  padding: 15px 30px;
}

.slide-menu nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.slide-menu nav ul li a:hover {
  color: #ff5733;
}

/* Hamburger Menu */
.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  cursor: pointer;
  z-index: 1001;
  margin-right: 10vh;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger-icon.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 8px);
}

.hamburger-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -8px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .logo img {
    margin-left: 0;
  }
  .header {
    padding: 1rem;
    justify-content: center;
  }

  .hamburger-icon {
    display: none;
  }
}
