@font-face {
  font-family: "Lemon-Milk";
  src: url("../../../public/Fonts/LEMONMILK-Bold.otf") format('opentype');
  font-weight: normal;
  font-style: normal;
}

.sponsorsection {
  margin: 0px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 70px;
  margin-top: 150px;
}

.titlesponsors {
  font-family: Lemon-Milk;
  font-size: 64px;
  margin: 24px;
  margin-bottom: 42px;
}

.scroller {
  max-width: 1200px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 20s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% + 0.8rem));
  }
}


.scroller>.scroller__inner>li {
  list-style-type: none;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.scroller>.scroller__inner>li>img {
  padding: 12px;
  width: 300px;
  height: 200px;
  transition: transform 0.5s ease;
}


#axisbanklogo,
#gunaagrologo,
#protienlogo {
  width: 340px;
  height: 100px;
}

#someonelogo {
  width: 320px;
  height: 80px;

}

#fasttrack {
  width: 320px;
  height: 280px;
}

#unstop {
  width: 340px;
  height: 130px;
}

#shekunjlogo {
  width: 250px;
  height: 350px;
}

#Trip247logo {
  width: 250px;
  height: 200px;
}

#kitchen,
#vadapavlogo {
  width: 200px;
  height: 200px;
}

#PCE {
  width: 350px;
  height: 300px;
}

#megamind {
  width: 300px;
  height: 180px;
}

#tealogylogo {
  width: 300px;
  height: 200px;
}

#hdfc {
  width: 300px;
  height: 150px;
}


#santushtilogo {
  width: 200px;
  height: 200px;
}

#sbilogo {
  width: 200px;
  height: 100px;
}

.scroller>.scroller__inner>li>img:hover {
  transform: scale(1.1);
}

.scroller>.scroller__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
}





@media (max-width : 768px){
  .sponsorsection{
    display: inline;
  }

  .titlesponsors{
    font-size: 38px;
    margin-top: 200px;
    text-align: center;
    width: 90%;

  }
}

@media (max-width : 900px){
  .sponsorsection{
    display: inline;
  }

  .titlesponsors{
    font-size: 42px;
    margin-top: 200px;
    text-align: center;
    width: 90%;

  }
}