.fab-container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;
  }
  
  .fab-button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #ff5733;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    z-index: 1001;
  }
  
  .fab-button:hover {
    transform: scale(1.05);
  }
  
  .fab-icon {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .fab-icon .bar {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 3px;
    transition: all 0.3s ease;
  }
  
  .fab-icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 8px);
  }
  
  .fab-icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .fab-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -8px);
  }
  
  .fab-menu {
    position: absolute;
    bottom: 70px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.3s ease;
    min-width: 120px;
  }
  
  .fab-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .fab-item {
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s ease;
  }
  
  .fab-item:hover {
    transform: scale(1.05);
  }
  
  .fab-item-label {
    display: block;
    background-color: #ff5733;
    color: white;
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
  }
  
  @media (min-width: 769px) {
    .fab-container {
      display: none;
    }
  }